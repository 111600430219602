export const ConfigUtil = {
    authDataKey: 'sysAuthData',
    authUrl: '/account',
    homeUrl: '/tabs/country',
    profileUrl: '/tabs/profile',
    countryKey: 'milesplacesCountries',
    countryType: 'country',
    categories: {
        
    }
};
