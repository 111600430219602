import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators/catchError';
import { Plugins } from '@capacitor/core';

import { environment } from '../../../environments/environment';
import { ConfigUtil } from './config.util';

@Injectable()
export class ApiServiceUtil {
  private headersConfig: any = {
     // 'Content-Type': 'application/json',
     // Accept: 'application/json'
  };

  private headers: any = '';

  public constructor(private http: HttpClient) {}

  // Handle get request
  public get(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return from(Plugins.Storage.get({key: ConfigUtil.authDataKey})).switchMap((response: any) => {
        const jsonParse = JSON.parse(response.value);
        if (jsonParse !== null) {
          this.headersConfig['Authorization'] = `Bearer ${jsonParse.authToken}`;
          this.headers = new HttpHeaders(this.headersConfig);
          return this.http
           .get(`${environment.apiBaseUrl}${path}`, {
            headers: this.headers,
            params
          })
          .pipe(catchError(this.formatErrors));
      } else {
        return this.http
          .get(`${environment.apiBaseUrl}${path}`, { params })
          .pipe(catchError(this.formatErrors));
      }
    });
  }

  // Handle post request
  public post(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    /*May need it JSON.stringify(body),*/
    return from(Plugins.Storage.get({key: ConfigUtil.authDataKey})).switchMap(
      (response: any) => {
        const jsonParse = JSON.parse(response.value);
        if (jsonParse !== null) {
          // Send request with token
          this.headersConfig['Authorization'] = `Bearer ${jsonParse.authToken}`;
          this.headers = new HttpHeaders(this.headersConfig);
          return this.http
            .post(`${environment.apiBaseUrl}${path}`, body, {
              headers: this.headers,
              params
            })
            .pipe(catchError(this.formatErrors));
        } else {
          return this.http
            .post(`${environment.apiBaseUrl}${path}`, body, { params })
            .pipe(catchError(this.formatErrors));
        }
      }
    );
  }

  //Handle put request
  public put(path: string,body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    /*May need JSON.stringify(body)*/
    return from(Plugins.Storage.get({key: ConfigUtil.authDataKey})).switchMap(
      (response: any) => {
        const jsonParse = JSON.parse(response.value);
        if (jsonParse !== null) {
          // Send request with token
          this.headersConfig['Authorization'] = `Bearer ${jsonParse.authToken}`;
          this.headers = new HttpHeaders(this.headersConfig);
          return this.http
            .put(`${environment.apiBaseUrl}${path}`, body, {
              headers: this.headers,
              params
            })
            .pipe(catchError(this.formatErrors));
        } else {
          return this.http
            .put(`${environment.apiBaseUrl}${path}`, body, { params })
            .pipe(catchError(this.formatErrors));
        }
      }
    );
  }

  // Handle delete
  public delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return from(Plugins.Storage.get({key: ConfigUtil.authDataKey})).switchMap(
      (response: any) => {
        const jsonParse = JSON.parse(response.value);
        if (jsonParse !== null){
          this.headersConfig['Authorization'] = `Bearer ${jsonParse.authToken}`;
          this.headers = new HttpHeaders(this.headersConfig);
          return this.http
            .delete(`${environment.apiBaseUrl}${path}`, {
              headers: this.headers,
              params
            })
            .pipe(catchError(this.formatErrors));
        } else {
          return this.http
            .put(`${environment.apiBaseUrl}${path}`, { params })
            .pipe(catchError(this.formatErrors));
        }
      }
    );
  }

  // Handle file upload
  public fileUpload(path: string, body: any, params: HttpParams = new HttpParams()): Observable<any> {
      /*May need it JSON.stringify(body),*/
      return from(Plugins.Storage.get({key: ConfigUtil.authDataKey})).switchMap(
        (response: any) => {
          const jsonParse = JSON.parse(response.value);
          if (jsonParse !== null) {
            // Send request with token
            this.headersConfig['Authorization'] = `Bearer ${jsonParse.authToken}`;
            this.headers = new HttpHeaders(this.headersConfig);
            return this.http.post(`${environment.apiBaseUrl}${path}`, body, {
                headers: this.headers,
                params
              })
              .pipe(catchError(this.formatErrors));
          } else {
            return this.http.post(`${environment.apiBaseUrl}${path}`, body, { params })
              .pipe(catchError(this.formatErrors));
          }
        }
          );
  }



  private formatErrors(errors: any): any {
    console.log(errors.status)
    if (errors.status === 401 || errors.status === 0) {
      Plugins.Storage.remove({key: ConfigUtil.authDataKey});
                location.reload(true);
    }

    return throwError(errors);
  }
}
